<template>
  <v-checkbox color="gray" class="ml-2" :ripple="false" v-model="checked" />
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    checked: {
      get() {
        const { userId } = this.member;
        return !!this.selectedRows?.find(m => m.userId === userId);
      },
      set() {}
    }
  }
};
</script>
