<template>
  <div class="cr-project-member-wrapper" @click="$emit('click:row', member)">
    <Checkbox v-bind="$props" v-on="$listeners" />
    <div class="cr-project-member">
      <FirstRow v-bind="$props" v-on="$listeners" />
      <SecondRow v-bind="$props" v-on="$listeners" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-project-member-wrapper {
  display: flex;
  position: relative;
  border-bottom: thin solid rgba(0, 0, 0, 0.08);
  &:hover::before {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    background: rgba(0, 0, 0, 0.03);
  }

  .cr-project-member {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    padding: 0px 12px;
    max-width: calc(100% - 40px);
  }
}
</style>

<script>
import Checkbox from "./Checkbox.vue";
import FirstRow from "./firstRow";
import SecondRow from "./secondRow";

export default {
  components: { Checkbox, FirstRow, SecondRow },
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  }
};
</script>
